<template>
  <div>
fdsdfa
  </div>
</template>
<style>

</style>
<script>
    export default {
        components: {},
        data() {
            return {

            }
        },
        mounted() {
            if (this.$route.query.token) {
              this.$api.setToken({access_token: this.$route.query.token});
              this.$api.post('/auth/session').then(response => {
                this.$auth.silentLogin(response.data);
                if (this.$route.query.r) {
                  //  this.$router.push({name: this.$route.query.r});
                } else {
                 // this.$router.push({name: 'Dashboard'});
                }
              });
            }
        },
    }
</script>
